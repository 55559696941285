<script>
import GlobalVue from "@helper/Global.vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "@helper/Gen";
import { MailIcon, PhoneIcon, SmartphoneIcon } from "vue-feather-icons";

export default {
  extends: GlobalVue,
  components: {
    MailIcon,
    PhoneIcon,
    SmartphoneIcon
  },
  data() {
    return {
      socialMedia: [],
      contact: [],
    };
  },
  mounted() {
    this.getContact();
  },
  computed: {
    yearcopy() {
      let year = 2021;
      let cyear = new Date().getFullYear();
      return year == cyear ? year : year + " - " + cyear;
    },
  },
  methods: {
    getContact() {
      Gen.apirest("/footer", {}, (err, resp) => {
        if (err) console.log("Footer" + err);

        this.socialMedia = resp.data.socialMedia;
        this.contact = resp.data.contact;

        this.sanitizeSocialMedia();
      });
    },
    sanitizeSocialMedia() {
      for (const key in this.socialMedia) {
        this.socialMedia[key].as_val = JSON.parse(
          `${this.socialMedia[key].as_val}`
        );
      }
    },
  },
};
</script>
<template>
  <footer itemscope itemtype="http://schema.org/WPFooter" id="footer" class="dark gap-cleaner">
    <div class="container">
      <!-- Footer Widgets
				============================================= -->
      <div class="footer-widgets-wrap">
        <div class="row align-items-center">
          <div class="col-md-6" itemscope itemtype="https://schema.org/School">
            <img
              :src="assets('fo_images', 'footer-logo.png')"
              alt="Logo SDI Al Hidayah"
              class="footer-logo mx-auto mx-md-0 mb-0"
              itemprop="logo"
            />
          </div>
          <div class="col-md-6">
            <div class="text-center text-md-right mt-3 mt-md-0">
              <span v-for="(value, name, index) in socialMedia" :key="index">
                <a
                  :href="value.as_val.url"
                  target="_blank"
                  v-if="value.as_val.active == 'Y'"
                  rel="noopener noreferrer"
                  :class="['social-icon', 'si-' + name]"
                >
                  <i :class="'icon-' + name"></i>
                  <i :class="'icon-' + name"></i>
                </a>
              </span>
              <div itemscope itemtype="https://schema.org/PostalAddress" class="d-block">
                <a
                  v-for="(i, n, ind) in contact"
                  :key="ind"
                  :href="
                    n == 'email' ? 'mailto:' + i.as_val : n == 'telephone' ? 'tel:' + i.as_val : 'tel:' + i.as_val
                  "
                  target="_blank"
                  class="contact"
                  :itemprop="n == 'phone' ? 'telephone' : n"
                >
                  <mail-icon v-if="n == 'email'" class="my-1"></mail-icon>
                  <phone-icon v-else-if="n == 'telephone'" class="my-1"></phone-icon>
                  <smartphone-icon v-else-if="n == 'phone'" class="my-1"></smartphone-icon>
                  <span>{{ i.as_val }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .footer-widgets-wrap end -->
    </div>

    <!-- Copyrights
			============================================= -->
    <div id="copyrights">
      <div class="container">
        <div class="text-center">
          <span itemprop="copyrightNotice">Copyrights &copy; {{ yearcopy }} All Rights Reserved by SD Islam
          Al Hidayah<br /></span>
          <div itemscope itemtype="https://schema.org/SiteNavigationElement" class="copyright-links">
            <router-link :to="{ name: 'Toc' }">
              <span>Syarat dan Ketentuan</span>
            </router-link>
            /
            <router-link :to="{ name: 'Privacy' }">Kebijakan Privasi</router-link>
            /
            <router-link :to="{ name: 'Other' }">Lembaga Lainnya</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- #copyrights end -->
  </footer>
</template>

<style>
  .footer-bg {
    background-color: #ffffff;
  }
</style>
